import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useRef, useState, VFC } from 'react';
import { InStockAvailabilityKey } from '../../../constants/site-consts';
import { Abbreviations } from '../../../constants/units-and-abbreviations';
import { useGtmTracking } from '../../../gtm-tracking/hooks/use-gtm-tracking';
import { convertProductToGtmData } from '../../../hooks/lead-checkout/use-hire-gtm-tracking-data';
import { useAccordionController } from '../../../hooks/use-accordion-controller';
import { useScrollLock } from '../../../hooks/use-scroll-lock';
import { PdpVanMeasurementModal } from '../../../lib/api/models/umbraco/product-details';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { hesselViewModels } from '../../../lib/view-models';
import { MEDIA_URL } from '../../../utils/environment-constants';
import { getFormattedValue, getUniqueId, isEquipmentLineValid, isNullOrEmpty, isNumber } from '../../../utils/helpers';
import { KeyValueCard } from '../../shared';
import { SimpleAccordion, SimpleAccordionProps } from '../../shared/simple-accordion';
import { AccordionConfigHeader } from '../configuration-accordion-header/configuration-accordion-header.component';
import { VanMeasureGuideModal } from '../vans/van-measure-guide-modal/van-measure-guide-modal.component';
import {
    ColorAndPrice,
    ColorPrice,
    ColorsWrapper,
    OptionsWrapper,
    StyledCheckmark,
    StyledColorCircle,
    StyledConfigHeader,
    StyledConfigIcon,
    StyledConfigWrapper,
    StyledInfoButton,
} from './configuration-accordion.styled';
import { findCarInFamilyThatMatchesConfigValue, useConfigurationUtility } from './use-configuration-utility';

type ConfigurationAccordionProps = {
    vanHeightModal: PdpVanMeasurementModal;
    vanLengthModal: PdpVanMeasurementModal;
};

const accordionBaseProps: Pick<SimpleAccordionProps, 'title' | 'border' | 'headerSize' | 'textColor' | 'contentPadding' | 'toggleIndicator'> = {
    title: '',
    border: 'bottom',
    headerSize: '0px',
    textColor: '0B0B0B',
    contentPadding: '21px 0px',
    toggleIndicator: 'plus',
};

enum OpenDialog {
    None,
    VanLengthModal,
    VanHeightModal,
}

export const ConfigurationAccordion: VFC<ConfigurationAccordionProps> = ({ vanHeightModal, vanLengthModal }) => {
    const [openDialog, setOpenDialog] = useState(OpenDialog.None);
    const { applyScrollBars, hideScrollBars } = useScrollLock();
    const router = useRouter();
    const {
        carFamily,
        selectedProduct,
        selectedCarType,
        selectedFuelType,
        selectedTransmissionType,
        selectedTractionWheels,
        selectedBatteryInformation,
        selectedColor,
        selectedLengthType,
        selectedHeightType,
        allConfigurations,
        availableConfiguration,
        productUpdateFinished,
        globalPlpSettings,
        ownershipMode,
        selectedTab,
        selectedEquipmentLine,
    } = ProductDetailsStore.useStoreState((state) => state);

    /**
     * REMEMBER: Update the indexes here if the order of accordion sections changed
     */
    const Initial_Open_Accordion_Index = useMemo(() => {
        const accordionsToOpen: number[] = [];
        if (selectedProduct?.availability === 'Order' && selectedProduct?.colors.length > 1) {
            accordionsToOpen.push(0);
        }
        if (allConfigurations.equipmentLines.length > 1) {
            accordionsToOpen.push(1);
        }
        return accordionsToOpen;
    }, [allConfigurations.equipmentLines, selectedProduct?.availability, selectedProduct?.colors]);
    const { registerAccordion } = useAccordionController(Initial_Open_Accordion_Index);

    const {
        setCarType,
        setFuelType,
        setTransmissionType,
        setTractionWheels,
        setBatteryInformation,
        setColor,
        setProductUpdateFinished,
        setHeightType,
        setLengthType,
        setEquipmentLine,
    } = ProductDetailsStore.useStoreActions((actions) => actions);

    const { getPriceDifference } = useConfigurationUtility(carFamily, ownershipMode, selectedTab, selectedProduct);

    const currentProduct = useRef(selectedProduct);
    const currentColorName = useRef(selectedColor?.name);

    const carTypeIcon = useMemo(() => {
        const filterIcon = globalPlpSettings?.filterGroupLabels
            .find((x) => x.filterGroupKey === 'carType.keyword')
            ?.optionIcons.find((i) => i.optionKey.toLowerCase() === selectedCarType?.toLocaleLowerCase())?.optionIcon;

        return filterIcon
            ? {
                  name: filterIcon.name,
                  url: `${MEDIA_URL}/` + filterIcon.src,
              }
            : undefined;
    }, [globalPlpSettings?.filterGroupLabels, selectedCarType]);

    useEffect(() => {
        if (selectedProduct) {
            if (selectedProduct.id === currentProduct.current?.id && selectedColor?.name === currentColorName.current) {
                return;
            }

            currentProduct.current = selectedProduct;
            currentColorName.current = selectedColor?.name;

            // Parse the current URL to get query parameters
            const currentSearchParams = new URLSearchParams(window.location.search);
            const companyQueryParam = currentSearchParams.get('company') === 'true' ? 'company=true' : '';

            let newUrl = selectedProduct.url.toLocaleLowerCase();

            if (selectedProduct.availability === 'Order' && selectedColor?.name) {
                const searchParams = new URLSearchParams();

                if (companyQueryParam) {
                    searchParams.set('company', 'true');
                }

                searchParams.set('color', selectedColor.name);

                newUrl = `${newUrl}?${searchParams.toString()}`;
            } else if (companyQueryParam) {
                newUrl = `${newUrl}?${companyQueryParam}`;
            }

            router.replace(newUrl, undefined, { shallow: true });
        }
    }, [router, selectedColor?.name, selectedProduct]);

    /**
     * Checks if the price per variant is available for the selected tab
     * This issue probably exists for other facets as well.
     * Right now it's only used for the battery capacity facet.
     * @param batteryInfo
     */
    const pricePerVariantIsAvailable = useCallback(
        (batteryInfo: hesselViewModels.ElCarBatteryInfo, tab: hesselViewModels.OwnershipTab) => {
            const car = carFamily.find((x) => {
                return x.batteryCapacity === batteryInfo.capacity && x.range === batteryInfo.range;
            });

            if (!car) {
                return false;
            }

            switch (tab) {
                case 'Car Privat Leasing':
                    return !!car?.purchaseTypes.privateLeasing && car.purchaseTypes.privateLeasing.show;

                case 'Car HiRE':
                    return !!car?.purchaseTypes.hire && car.purchaseTypes.hire.show;

                case 'Car Finansiering':
                    return !!car?.purchaseTypes.financing && car.purchaseTypes.financing.show;

                case 'Car Kontant':
                    return !!car?.purchaseTypes.cash && car.purchaseTypes.cash.show;

                case 'Operationel Leasing':
                    return !!car?.purchaseTypes.operationalLeasing && car.purchaseTypes.operationalLeasing.show;

                case 'Finansiel Leasing':
                    return !!car?.purchaseTypes.financialLeasing && car.purchaseTypes.financialLeasing.show;

                case 'Van Finansiering':
                    return !!car?.purchaseTypes.financing && car.purchaseTypes.financing.show;

                case 'Van Kontant':
                    return !!car?.purchaseTypes.cash && car.purchaseTypes.cash.show;

                case 'No Value':
                default:
                    return false;
            }
        },
        [carFamily]
    );

    const availableBatteryCapacities = useMemo(() => {
        const filteredResult = allConfigurations.batteryInfoList
            .filter((x) => !isNullOrEmpty(x.capacity) && pricePerVariantIsAvailable(x, selectedTab))
            .sort((a, b) => +a.capacity - +b.capacity);
        return filteredResult;
    }, [allConfigurations.batteryInfoList, pricePerVariantIsAvailable, selectedTab]);

    const getColorPriceDifference = useCallback(
        (color: hesselViewModels.ProductColor) => {
            if (!selectedProduct || !selectedColor) {
                return `+0 ${selectedTab.includes('Leasing') || selectedTab === 'Car HiRE' ? `${Abbreviations.KR_SLASH_MD}` : `${Abbreviations.KR}`}`;
            }

            let colorPriceDifference = 0;

            if (ownershipMode === 'Køb') {
                colorPriceDifference = (color.cashPrice ?? 0) - (selectedColor.cashPrice ?? 0);
            } else {
                if (selectedTab === 'Car HiRE') {
                    colorPriceDifference = (color.monthlyHirePrice ?? 0) - (selectedColor.monthlyHirePrice ?? 0);
                } else if (selectedTab === 'Operationel Leasing') {
                    colorPriceDifference = (color.monthlyOperationalLeasingPrice ?? 0) - (selectedColor.monthlyOperationalLeasingPrice ?? 0);
                } else if (selectedTab === 'Finansiel Leasing') {
                    colorPriceDifference = (color.monthlyFinancialLeasingPrice ?? 0) - (selectedColor.monthlyFinancialLeasingPrice ?? 0);
                } else if (selectedTab === 'Car Privat Leasing') {
                    colorPriceDifference = (color.monthlyPrivateLeasingPrice ?? 0) - (selectedColor.monthlyPrivateLeasingPrice ?? 0);
                }
            }

            return `${colorPriceDifference >= 0 ? '+' : ''}${getFormattedValue(colorPriceDifference)} ${
                selectedTab.includes('Leasing') || selectedTab === 'Car HiRE' ? `${Abbreviations.KR_SLASH_MD}` : `${Abbreviations.KR}`
            }`;
        },
        [selectedTab, ownershipMode, selectedColor, selectedProduct]
    );

    // GTM
    const { trackHirePdp } = useGtmTracking();
    const tracker = trackHirePdp();

    useEffect(() => {
        if (productUpdateFinished) {
            const convertedProduct = convertProductToGtmData(selectedProduct, selectedColor);

            if (convertedProduct && convertedProduct.carData) {
                tracker.viewItem(convertedProduct.carData);
            }
            setProductUpdateFinished(false);
        }
    }, [productUpdateFinished, selectedColor, selectedProduct, setProductUpdateFinished, tracker]);

    const sendTrackingData = () => {};

    const formatMeasurement = useCallback((measurement: number) => `${(measurement / 1000).toFixed(2).replace('.', ',')} m`, []);

    const availableColors = useMemo(() => {
        const colorsWithPrice = selectedProduct?.colors.filter((x) => {
            if (selectedTab === 'Car HiRE' && (x.monthlyHirePrice === undefined || x.monthlyHirePrice === null)) return false;
            if (selectedTab === 'Car Privat Leasing' && (x.monthlyPrivateLeasingPrice === undefined || x.monthlyPrivateLeasingPrice === null)) {
                return false;
            }
            if (
                selectedTab === 'Operationel Leasing' &&
                (x.monthlyOperationalLeasingPrice === undefined || x.monthlyOperationalLeasingPrice === null)
            ) {
                return false;
            }
            if (selectedTab === 'Finansiel Leasing' && (x.monthlyFinancialLeasingPrice === undefined || x.monthlyFinancialLeasingPrice === null)) {
                return false;
            }
            if (ownershipMode === 'Køb' && (x.cashPrice === undefined || x.cashPrice === null)) return false;
            return true;
        });
        return colorsWithPrice;
    }, [ownershipMode, selectedProduct?.colors, selectedTab]);

    const showEquipmentLines = useMemo(() => {
        return allConfigurations.equipmentLines.length > 0;
    }, [allConfigurations.equipmentLines.length]);

    return (
        <>
            <StyledConfigWrapper>
                {selectedProduct ? (
                    <>
                        <StyledConfigHeader>Konfiguration</StyledConfigHeader>
                        {/* Colors */}
                        <SimpleAccordion
                            {...accordionBaseProps}
                            {...registerAccordion(0)}
                            customTitle={
                                <AccordionConfigHeader
                                    label="Farve"
                                    value={
                                        selectedProduct.availability === 'Used' || selectedProduct.availability === 'Engros'
                                            ? selectedProduct.color
                                            : selectedProduct.colors?.find((x) => x.id === selectedColor?.id)?.name ?? ''
                                    }
                                    price={''}
                                    colorUrl={selectedProduct.colors?.find((c) => c.id === selectedColor?.id)?.resource.data}
                                />
                            }
                            disableAccordion={selectedProduct.availability === InStockAvailabilityKey || selectedProduct?.colors.length <= 1}
                        >
                            <ColorsWrapper>
                                {availableColors?.map((color, index) => {
                                    return (
                                        <ColorAndPrice key={`color-and-price-circle-${getUniqueId()}-id:${color.id}-${index}`}>
                                            <StyledColorCircle
                                                imageUrl={color.resource.data}
                                                selected={color.id === selectedColor?.id}
                                                onClick={() => {
                                                    setColor(color);
                                                    sendTrackingData();
                                                }}
                                            >
                                                {color.id === selectedColor?.id ? (
                                                    <StyledCheckmark width={19} height={19} viewBox="-2 -3  30 30" />
                                                ) : null}
                                            </StyledColorCircle>
                                            <ColorPrice>{getColorPriceDifference(color)}</ColorPrice>
                                        </ColorAndPrice>
                                    );
                                })}
                            </ColorsWrapper>
                        </SimpleAccordion>
                        {/* Equipment lines */}
                        {showEquipmentLines && (
                            <SimpleAccordion
                                {...accordionBaseProps}
                                {...registerAccordion(1)}
                                customTitle={<AccordionConfigHeader label="Udstyrlinje" value={selectedEquipmentLine ?? ''} price={''} />}
                                disableAccordion={allConfigurations.equipmentLines.length === 1}
                            >
                                <OptionsWrapper>
                                    {allConfigurations.equipmentLines
                                        .sort((a, b) => {
                                            const aDiff = getPriceDifference(a, 'Equipment Line');
                                            const bDiff = getPriceDifference(b, 'Equipment Line');
                                            return aDiff.raw - bDiff.raw;
                                        })
                                        .map((eqLine, index) => {
                                            const eqLineAvailable =
                                                availableConfiguration.equipmentLines.includes(eqLine) &&
                                                isEquipmentLineValid(carFamily, eqLine, selectedTab, selectedProduct);
                                            const price =
                                                eqLine === selectedEquipmentLine
                                                    ? `${
                                                          ownershipMode === 'Leasing' ? '+ 0 ' + Abbreviations.KR_SLASH_MD : '+ 0 ' + Abbreviations.KR
                                                      }`
                                                    : eqLineAvailable
                                                    ? getPriceDifference(eqLine, 'Equipment Line', ownershipMode === 'Leasing').prettified
                                                    : '';
                                            return (
                                                <KeyValueCard
                                                    key={`equipment-${eqLine}-${index}`}
                                                    label={eqLine}
                                                    value={price}
                                                    disabled={!eqLineAvailable}
                                                    selected={eqLine === selectedEquipmentLine}
                                                    onClick={() => setEquipmentLine(eqLine)}
                                                />
                                            );
                                        })}
                                </OptionsWrapper>
                            </SimpleAccordion>
                        )}
                        {/* Car Types */}
                        {selectedCarType ? (
                            <SimpleAccordion
                                {...accordionBaseProps}
                                {...registerAccordion(2)}
                                customTitle={
                                    <AccordionConfigHeader
                                        label={'Biltype'}
                                        value={selectedCarType ?? ''}
                                        price={''}
                                        icon={carTypeIcon ? <StyledConfigIcon alt="Biltype ikon" src={carTypeIcon.url} /> : undefined}
                                    />
                                }
                                disableAccordion={selectedProduct.availability === InStockAvailabilityKey || allConfigurations.carTypes.length === 1}
                            >
                                <OptionsWrapper>
                                    {allConfigurations.carTypes.filter(Boolean).map((cType, idx) => {
                                        const cTypeAvailable = availableConfiguration.carTypes.includes(cType);

                                        const externalIcon = globalPlpSettings?.filterGroupLabels
                                            .find((x) => x.filterGroupKey === 'carType.keyword')
                                            ?.optionIcons.find((i) => i.optionKey.toLowerCase() === cType?.toLocaleLowerCase());

                                        return (
                                            <KeyValueCard
                                                key={`car-config-type-${cType}-${idx}`}
                                                label={cType}
                                                value={
                                                    cType === selectedCarType
                                                        ? '+ 0 kr'
                                                        : cTypeAvailable
                                                        ? getPriceDifference(cType, 'Car Type', ownershipMode === 'Leasing').prettified
                                                        : ''
                                                }
                                                selected={cType === selectedCarType}
                                                externalIcon={
                                                    externalIcon
                                                        ? {
                                                              name: `${cType}`,
                                                              url: `${MEDIA_URL}/` + externalIcon.optionIcon.src,
                                                          }
                                                        : undefined
                                                }
                                                onClick={() => {
                                                    if (cType !== selectedCarType) {
                                                        setCarType(cType);
                                                        sendTrackingData();
                                                    }
                                                }}
                                                disabled={!cTypeAvailable}
                                            />
                                        );
                                    })}
                                </OptionsWrapper>
                            </SimpleAccordion>
                        ) : null}

                        {/* Length and height are only relevant to Vans */}
                        {selectedProduct?.vehicleType === 'Van' && (
                            <>
                                {/* Car Length */}
                                {allConfigurations.lengthTypes.every((x) => x && x.length > 0) ? (
                                    <SimpleAccordion
                                        {...accordionBaseProps}
                                        {...registerAccordion(3)}
                                        customTitle={
                                            <AccordionConfigHeader
                                                label={'Længde'}
                                                value={
                                                    selectedLengthType ? `${selectedLengthType} - ${formatMeasurement(selectedProduct.length)}` : ''
                                                }
                                                price={''}
                                                icon={carTypeIcon ? <StyledConfigIcon alt="Længde ikon" src={carTypeIcon.url} /> : undefined}
                                            />
                                        }
                                        disableAccordion={
                                            selectedProduct.availability === InStockAvailabilityKey || allConfigurations.lengthTypes.length === 1
                                        }
                                    >
                                        <OptionsWrapper>
                                            {allConfigurations.lengthTypes
                                                .filter(Boolean)
                                                .sort()
                                                .map((lengthType) => {
                                                    const isLengthTypeAvailable = availableConfiguration.lengthTypes.includes(lengthType);
                                                    const carConfigurationForLength = findCarInFamilyThatMatchesConfigValue(
                                                        carFamily,
                                                        selectedProduct,
                                                        'Length Type',
                                                        lengthType
                                                    );

                                                    return (
                                                        <KeyValueCard
                                                            key={`length-config-type-${lengthType}`}
                                                            label={
                                                                carConfigurationForLength
                                                                    ? `${lengthType} (${formatMeasurement(carConfigurationForLength.length)})`
                                                                    : lengthType
                                                            }
                                                            value={
                                                                lengthType === selectedLengthType
                                                                    ? '+ 0 kr'
                                                                    : isLengthTypeAvailable
                                                                    ? getPriceDifference(lengthType, 'Length Type', ownershipMode === 'Leasing')
                                                                          .prettified
                                                                    : ''
                                                            }
                                                            selected={lengthType === selectedLengthType}
                                                            onClick={() => {
                                                                if (selectedLengthType !== lengthType) {
                                                                    setLengthType(lengthType);
                                                                    sendTrackingData();
                                                                }
                                                            }}
                                                            disabled={!isLengthTypeAvailable}
                                                        />
                                                    );
                                                })}
                                        </OptionsWrapper>
                                        <StyledInfoButton
                                            variant="link"
                                            onClick={() => {
                                                hideScrollBars();
                                                setOpenDialog(OpenDialog.VanLengthModal);
                                            }}
                                        >
                                            Hvordan måles længden?
                                        </StyledInfoButton>
                                    </SimpleAccordion>
                                ) : null}

                                {/* Car Height */}
                                {allConfigurations.heightTypes.every((x) => x && x.length) ? (
                                    <SimpleAccordion
                                        {...accordionBaseProps}
                                        {...registerAccordion(4)}
                                        customTitle={
                                            <AccordionConfigHeader
                                                label={'Højde'}
                                                value={
                                                    selectedHeightType ? `${selectedHeightType} - ${formatMeasurement(selectedProduct.height)}` : ''
                                                }
                                                price={''}
                                                icon={carTypeIcon ? <StyledConfigIcon alt="Højde ikon" src={carTypeIcon.url} /> : undefined}
                                            />
                                        }
                                        disableAccordion={
                                            selectedProduct.availability === InStockAvailabilityKey || allConfigurations.heightTypes.length === 1
                                        }
                                    >
                                        <OptionsWrapper>
                                            {allConfigurations.heightTypes
                                                .filter(Boolean)
                                                .sort()
                                                .map((heightType) => {
                                                    const heightTypeAvailable = availableConfiguration.heightTypes.includes(heightType);
                                                    const carConfigurationForHeight = findCarInFamilyThatMatchesConfigValue(
                                                        carFamily,
                                                        selectedProduct,
                                                        'Height Type',
                                                        heightType
                                                    );

                                                    return (
                                                        <KeyValueCard
                                                            key={`height-config-type-${heightType}`}
                                                            label={
                                                                carConfigurationForHeight
                                                                    ? `${heightType} (${formatMeasurement(carConfigurationForHeight.height)})`
                                                                    : heightType
                                                            }
                                                            value={
                                                                heightType === selectedHeightType
                                                                    ? '+ 0 kr'
                                                                    : heightTypeAvailable
                                                                    ? getPriceDifference(heightType, 'Height Type', ownershipMode === 'Leasing')
                                                                          .prettified
                                                                    : ''
                                                            }
                                                            selected={heightType === selectedHeightType}
                                                            onClick={() => {
                                                                if (selectedHeightType !== heightType) {
                                                                    setHeightType(heightType);
                                                                    sendTrackingData();
                                                                }
                                                            }}
                                                            disabled={!heightTypeAvailable}
                                                        />
                                                    );
                                                })}
                                        </OptionsWrapper>
                                        <StyledInfoButton
                                            variant="link"
                                            onClick={() => {
                                                hideScrollBars();
                                                setOpenDialog(OpenDialog.VanHeightModal);
                                            }}
                                        >
                                            Hvordan måles højden?
                                        </StyledInfoButton>
                                    </SimpleAccordion>
                                ) : null}
                            </>
                        )}

                        {/* Fuel Types */}
                        <SimpleAccordion
                            {...accordionBaseProps}
                            {...registerAccordion(5)}
                            customTitle={<AccordionConfigHeader label={'Drivmiddel'} value={selectedFuelType ?? ''} price={''} />}
                            disableAccordion={selectedProduct.availability === InStockAvailabilityKey || allConfigurations.fuelTypes.length === 1}
                        >
                            <OptionsWrapper>
                                {allConfigurations.fuelTypes
                                    .filter(Boolean)
                                    .sort()
                                    .map((fType, idx) => {
                                        const fTypeAvailable = availableConfiguration.fuelTypes.includes(fType);

                                        return (
                                            <KeyValueCard
                                                key={`car-config-fuel-${fType}-${idx}`}
                                                label={fType}
                                                value={
                                                    fType === selectedFuelType
                                                        ? '+ 0 kr'
                                                        : fTypeAvailable
                                                        ? getPriceDifference(fType, 'Fuel Type', ownershipMode === 'Leasing').prettified
                                                        : ''
                                                }
                                                selected={fType === selectedFuelType}
                                                onClick={() => {
                                                    if (fType !== selectedFuelType) {
                                                        setFuelType(fType);
                                                        sendTrackingData();
                                                    }
                                                }}
                                                disabled={!fTypeAvailable}
                                            />
                                        );
                                    })}
                            </OptionsWrapper>
                        </SimpleAccordion>

                        {/* Traction Wheels */}
                        <SimpleAccordion
                            {...accordionBaseProps}
                            {...registerAccordion(6)}
                            customTitle={<AccordionConfigHeader label={'Trækhjul'} value={selectedTractionWheels ?? ''} price={''} />}
                            disableAccordion={
                                selectedProduct.availability === InStockAvailabilityKey || allConfigurations.tractionWheels.length === 1
                            }
                        >
                            <OptionsWrapper>
                                {allConfigurations.tractionWheels
                                    .filter(Boolean)
                                    .sort()
                                    .map((tWh, idx) => {
                                        const trWheels = availableConfiguration.tractionWheels.includes(tWh);

                                        return (
                                            <KeyValueCard
                                                key={`car-config-traction-wheels-${tWh}-${idx}`}
                                                label={tWh}
                                                value={
                                                    tWh === selectedTractionWheels
                                                        ? '+ 0 kr'
                                                        : trWheels
                                                        ? getPriceDifference(tWh, 'Traction Wheels', ownershipMode === 'Leasing').prettified
                                                        : ''
                                                }
                                                selected={tWh === selectedTractionWheels}
                                                onClick={() => {
                                                    setTractionWheels(tWh);
                                                    sendTrackingData();
                                                }}
                                                disabled={!trWheels}
                                            />
                                        );
                                    })}
                            </OptionsWrapper>
                        </SimpleAccordion>

                        {/* Transmission Types */}
                        <SimpleAccordion
                            {...accordionBaseProps}
                            {...registerAccordion(7)}
                            border={selectedFuelType === 'El' ? 'bottom' : 'none'}
                            customTitle={<AccordionConfigHeader label={'Geartype'} value={selectedTransmissionType ?? ''} price={''} />}
                            disableAccordion={
                                selectedProduct.availability === InStockAvailabilityKey || allConfigurations.transmissionTypes.length === 1
                            }
                        >
                            <OptionsWrapper>
                                {allConfigurations.transmissionTypes
                                    .filter(Boolean)
                                    .sort()
                                    .map((tType, idx) => {
                                        const tTypeAvailable = availableConfiguration.transmissionTypes.includes(tType);

                                        return (
                                            <KeyValueCard
                                                key={`car-config-transmission-${tType}-${idx}`}
                                                label={tType}
                                                value={
                                                    tType === selectedTransmissionType
                                                        ? '+ 0 kr'
                                                        : tTypeAvailable
                                                        ? getPriceDifference(tType, 'Transmission Type', ownershipMode === 'Leasing').prettified
                                                        : ''
                                                }
                                                selected={tType === selectedTransmissionType}
                                                onClick={() => {
                                                    setTransmissionType(tType);
                                                    sendTrackingData();
                                                }}
                                                disabled={!tTypeAvailable}
                                            />
                                        );
                                    })}
                            </OptionsWrapper>
                        </SimpleAccordion>

                        {/* Battery Capacities */}
                        {selectedFuelType === 'El' ? (
                            <SimpleAccordion
                                {...accordionBaseProps}
                                {...registerAccordion(8)}
                                customTitle={
                                    <AccordionConfigHeader
                                        label={'Batterikapacitet'}
                                        value={
                                            selectedBatteryInformation
                                                ? `${getFormattedValue(
                                                      +selectedBatteryInformation.capacity.replace(',', '.')
                                                  )} kWh (${getFormattedValue(selectedProduct.range ?? 0)} km)`
                                                : ''
                                        }
                                        price={''}
                                    />
                                }
                                disableAccordion={
                                    selectedProduct.availability === InStockAvailabilityKey || allConfigurations.batteryInfoList.length === 1
                                }
                            >
                                <OptionsWrapper>
                                    {availableBatteryCapacities.map((batInfo, idx) => {
                                        const batteryCapAvailable = availableConfiguration.batteryInfoList.some(
                                            (x) => x.capacity === batInfo.capacity && x.range === batInfo.range
                                        );

                                        return (
                                            <KeyValueCard
                                                key={`car-battery-capacity-${batInfo}-${idx}`}
                                                label={`${
                                                    isNumber(batInfo.capacity) ? getFormattedValue(+batInfo.capacity) : batInfo.capacity
                                                } kWh (${getFormattedValue(batInfo.range ?? 0)} km)`}
                                                value={
                                                    batInfo.capacity === selectedBatteryInformation?.capacity
                                                        ? '+ 0 kr'
                                                        : batteryCapAvailable
                                                        ? getPriceDifference(batInfo.capacity, 'Battery Capacity', ownershipMode === 'Leasing')
                                                              .prettified
                                                        : ''
                                                }
                                                selected={
                                                    batInfo.capacity === selectedBatteryInformation?.capacity &&
                                                    batInfo.range === selectedBatteryInformation?.range
                                                }
                                                onClick={() => {
                                                    setBatteryInformation(batInfo);
                                                    sendTrackingData();
                                                }}
                                                disabled={!batteryCapAvailable}
                                            />
                                        );
                                    })}
                                </OptionsWrapper>
                            </SimpleAccordion>
                        ) : null}
                    </>
                ) : null}

                {selectedProduct?.vehicleType === 'Van' && !!vanLengthModal ? (
                    <VanMeasureGuideModal
                        visible={openDialog === OpenDialog.VanLengthModal}
                        onClose={() => {
                            setOpenDialog(OpenDialog.None);
                            applyScrollBars();
                        }}
                        modalSettings={vanLengthModal}
                    />
                ) : null}

                {selectedProduct?.vehicleType === 'Van' && !!vanHeightModal ? (
                    <VanMeasureGuideModal
                        visible={openDialog === OpenDialog.VanHeightModal}
                        onClose={() => {
                            setOpenDialog(OpenDialog.None);
                            applyScrollBars();
                        }}
                        modalSettings={vanHeightModal}
                    />
                ) : null}
            </StyledConfigWrapper>
        </>
    );
};
