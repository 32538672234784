import styled from 'styled-components';
import { device } from '../../../../lib/media-query';
import { ComplianceText } from '../../cars/car-leasing-tabs/components/compliance-text.component';

export const PopularityBadge = styled.div`
    background-color: ${(props) => props.theme.palette.primary.main};
    padding: 3px 6px;
`;

export const PopularityBadgeText = styled.p`
    color: #fcfcfc;
    font-size: 10px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 10px;
`;

export const IncludingDeliveryAndComplianceTextWrapper = styled.div`
    padding: 0 20px;
    grid-area: 3 / 1 / 4 / 3;
    margin-bottom: 20px;
    @media ${device.laptopS} {
        margin-bottom: revert;
    }
`;

export const PositionedComplianceText = styled(ComplianceText)``;

export const StyledIncludingDeliveryText = styled.p`
    color: #807f80;
    font-size: 11px;
    font-style: italic;
    font-weight: normal;
    line-height: 18px;
    margin-bottom: 5px;
`;

export const StyledTaxationPriceText = styled.p`
    color: #aeaeaf;
    font-size: 12px;
    margin-top: 4px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;

    img {
        opacity: 0.4;
    }
`;
