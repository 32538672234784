import { useCallback, useMemo } from 'react';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';

import { OrderCarModalForm } from '../order-car-modal/order-car-modal.types';
import { hesselViewModels } from '../../../lib/view-models';
import { hesselApiTypes } from '../../../lib/api/models/hessel-api';
import { OrderCarModalSubmitData } from './financial-types';
import { DateStyle, formatDate } from '../../../utils/helpers';
import { LeadVehicleData } from '../../../lib/api/lead/lead-api.types';

type UsePdpLeadSubmitDataReturnType = {
    getOrderCarModalSubmitData: (formValues: OrderCarModalForm, santanderFinancing?: hesselApiTypes.CarFinancing) => OrderCarModalSubmitData;
    vehicleToSubmit: LeadVehicleData | null;
};

type EquipmentInfo = {
    equipmentCode: string;
    equipmentName: string;
    equipmentPrice: number;
};

export const usePdpLeadSubmitData = (): UsePdpLeadSubmitDataReturnType => {
    const {
        selectedTab,
        selectedProduct: product,
        selectedColor: color,
        computedCashPrice,
        computedMonthlyPrice,
        computedDownPayment,
        computedColorPrice,
    } = ProductDetailsStore.useStoreState((state) => state);

    const getEquipmentPriceByFinanceTab = useCallback(
        (equipment: hesselViewModels.Equipment | hesselViewModels.EquipmentPackage) => {
            switch (selectedTab) {
                case 'Car Privat Leasing':
                    return equipment.monthlyPrivateLeasingPrice ?? null;
                case 'Car Finansiering':
                    return equipment.monthlyFinancialLeasingPrice ?? null;
                case 'Car Kontant':
                    return equipment.cashPrice ?? null;
                case 'Van Finansiering':
                    return equipment.cashPrice ?? null;
                case 'Car HiRE':
                    return equipment.monthlyHirePrice ?? null;
                case 'Finansiel Leasing':
                    return equipment.monthlyFinancialLeasingPrice ?? null;
                case 'Operationel Leasing':
                    return equipment.monthlyOperationalLeasingPrice ?? null;
                case 'Van Kontant':
                    return equipment.cashPrice ?? null;
                default:
                    return null;
            }
        },
        [selectedTab]
    );

    const vehicleImageUrl = useMemo(() => {
        const matchedResource = product?.resources.filter((x) => x.type === 'image' && x.colorCode === color?.code);

        if (!matchedResource || matchedResource.length === 0) {
            return (product?.resources?.find((x) => x.type === 'image') as hesselViewModels.Image)?.url ?? '';
        }

        const matched = matchedResource[0] as hesselViewModels.Image;
        return matched.url.replace('productdetailsfullsize', 'email').replace('{IMAGE-SIZE}', 'email');
    }, [color?.code, product?.resources]);

    const getOwnershipSubmitText = (selectedTab: hesselViewModels.OwnershipTab): string => {
        switch (selectedTab) {
            case 'Car Privat Leasing':
                return 'PrivateLeasing';
            case 'Car Finansiering':
            case 'Van Finansiering':
                return 'Financing';
            case 'Car Kontant':
            case 'Van Kontant':
                return 'Cash';
            case 'Finansiel Leasing':
                return 'FinancialLeasing';
            case 'Operationel Leasing':
                return 'OperationalLeasing';
            default:
                return '';
        }
    };

    const getEquipmentAndEquipmentPackageNames = useCallback(
        (selectedProduct?: hesselViewModels.ProductDetails) => {
            if (!selectedProduct) {
                return [];
            }

            const extraEquipment = selectedProduct.extraEquipment[selectedTab].reduce((acc, equipment) => {
                if (equipment.isSelected) {
                    return [
                        ...acc,
                        { equipmentCode: equipment.id, equipmentName: equipment.name, equipmentPrice: getEquipmentPriceByFinanceTab(equipment) ?? 0 },
                    ];
                }

                return acc;
            }, [] as Array<EquipmentInfo>);

            const extraEquipmentPackageList = selectedProduct.extraEquipmentPackages[selectedTab].reduce((acc, equipmentPkg) => {
                if (equipmentPkg.isSelected) {
                    return [
                        ...acc,
                        {
                            equipmentCode: equipmentPkg.id,
                            equipmentName: equipmentPkg.name ?? '',
                            equipmentPrice: getEquipmentPriceByFinanceTab(equipmentPkg) ?? 0,
                        },
                    ];
                }

                return acc;
            }, [] as Array<EquipmentInfo>);

            const optionalEquipment = selectedProduct.optionalEquipment[selectedTab].reduce((acc, optionalEq) => {
                if (optionalEq.isSelected) {
                    return [
                        ...acc,
                        {
                            equipmentCode: optionalEq.id,
                            equipmentName: optionalEq.name ?? '',
                            equipmentPrice: getEquipmentPriceByFinanceTab(optionalEq) ?? 0,
                        },
                    ];
                }

                return acc;
            }, [] as Array<EquipmentInfo>);

            return [...extraEquipment, ...extraEquipmentPackageList, ...optionalEquipment];
        },
        [getEquipmentPriceByFinanceTab, selectedTab]
    );

    const priceDataByFinanceTab = useMemo(() => {
        switch (selectedTab) {
            case 'Car Privat Leasing':
                return {
                    kmPerMonth: product?.purchaseTypes?.privateLeasing?.kilometersPerMonth ?? null,
                    baseMonthlyPrice: product?.purchaseTypes?.privateLeasing?.baseMonthlyPrice ?? null,
                    totalPrice: computedCashPrice,
                    monthlyPrice: computedMonthlyPrice,
                    downPayment: computedDownPayment,
                    colorPrice: computedColorPrice,
                    durationMonths: product?.purchaseTypes?.privateLeasing?.durationMonths ?? null,
                };
            case 'Car Finansiering':
            case 'Van Finansiering':
                return {
                    kmPerMonth: null,
                    baseMonthlyPrice: product?.purchaseTypes?.financing?.baseMonthlyPrice ?? null,
                    totalPrice: computedCashPrice,
                    monthlyPrice: computedMonthlyPrice,
                    downPayment: computedDownPayment,
                    colorPrice: computedColorPrice,
                    durationMonths: product?.purchaseTypes?.financing?.durationMonths ?? null,
                };

            case 'Car Kontant':
            case 'Van Kontant':
                return {
                    kmPerMonth: null,
                    baseMonthlyPrice: product?.purchaseTypes?.cash?.basePrice ?? null,
                    totalPrice: computedCashPrice,
                    monthlyPrice: computedMonthlyPrice,
                    downPayment: computedDownPayment,
                    colorPrice: computedColorPrice,
                    durationMonths: null,
                };
            case 'Car HiRE':
                return {
                    kmPerMonth: product?.purchaseTypes?.hire?.kilometersPerMonth ?? null,
                    baseMonthlyPrice: product?.purchaseTypes?.hire?.baseMonthlyPrice ?? null,
                    totalPrice: computedCashPrice,
                    monthlyPrice: computedMonthlyPrice,
                    downPayment: computedDownPayment,
                    colorPrice: computedColorPrice,
                    durationMonths: product?.purchaseTypes?.hire?.durationFrom ?? null,
                };
            case 'Finansiel Leasing':
                return {
                    kmPerMonth: product?.purchaseTypes?.financialLeasing?.kilometersPerMonth ?? null,
                    baseMonthlyPrice: product?.purchaseTypes?.financialLeasing?.baseMonthlyPrice ?? null,
                    totalPrice: computedCashPrice,
                    monthlyPrice: computedMonthlyPrice,
                    downPayment: computedDownPayment,
                    colorPrice: computedColorPrice,
                    durationMonths: product?.purchaseTypes?.financialLeasing.durationMonths ?? null,
                };
            case 'Operationel Leasing':
                return {
                    kmPerMonth: product?.purchaseTypes?.operationalLeasing?.kilometersPerYear ?? null,
                    baseMonthlyPrice: product?.purchaseTypes?.operationalLeasing?.baseMonthlyPrice ?? null,
                    totalPrice: computedCashPrice,
                    monthlyPrice: computedMonthlyPrice,
                    downPayment: computedDownPayment,
                    colorPrice: computedColorPrice,
                    durationMonths: product?.purchaseTypes?.operationalLeasing?.durationMonths ?? null,
                };
            default:
                return { kmPerMonth: null, baseMonthlyPrice: null, totalPrice: null, monthlyPrice: null, downPayment: null, colorPrice: null };
        }
    }, [computedCashPrice, computedColorPrice, computedDownPayment, computedMonthlyPrice, product, selectedTab]);

    const vehicleToSubmit: LeadVehicleData | null = useMemo(() => {
        if (!product) {
            return null;
        }

        const { kmPerMonth, durationMonths, totalPrice, monthlyPrice, downPayment, colorPrice } = priceDataByFinanceTab;

        return {
            vehicleId: product.entityId ?? 0,
            referenceNumber: product.referenceNumber ?? '',
            brand: product.brand,
            model: product.modelTitle,
            variant: product.name,
            kmPerMonth: kmPerMonth ?? 0,
            leasingStart: formatDate(new Date(), DateStyle.booking_format),
            leasingMonths: durationMonths ?? 0,
            price: totalPrice ?? null,
            monthlyPrice: monthlyPrice ?? null,
            establishmentFee: downPayment ?? null,
            equipment: getEquipmentAndEquipmentPackageNames(product),
            color: color?.name ?? '',
            colorPrice: colorPrice ?? null,
            colorUrl: color?.resource.data ?? '',
            vehicleImageUrl: vehicleImageUrl,
            url: product.url,
            type: product.vehicleType.toString(),
            availability: product.availability.toString(),
            entityId: product.entityId.toString(),
        };
    }, [color?.name, color?.resource.data, getEquipmentAndEquipmentPackageNames, priceDataByFinanceTab, product, vehicleImageUrl]);

    const getOrderCarModalSubmitData = (formValues: OrderCarModalForm, santanderFinancing?: hesselApiTypes.CarFinancing): OrderCarModalSubmitData => {
        if (!product) {
            throw new Error("Can't get order car modal submit data without a product");
        }

        return {
            name: formValues.fullName ?? '',
            email: formValues.email ?? '',
            phone: formValues.phone ?? '',
            ownershipType: getOwnershipSubmitText(selectedTab),
            vehicle: vehicleToSubmit,
            existingVehicle:
                formValues.customer && formValues.customer.wantsToTrade
                    ? {
                          registrationNumber: formValues.customer.regNumber ?? '',
                          odometerReadingInKm: +(formValues.customer.odometer ?? 0),
                          comment: formValues.customer.message ?? '',
                      }
                    : null,
            consent: !!formValues.termsAccepted,
            consentText: formValues.consentCode,
            financing:
                (selectedTab === 'Car Finansiering' || selectedTab === 'Van Finansiering') && santanderFinancing
                    ? {
                          durationMonths: santanderFinancing.paymentTerms,
                          downPayment: santanderFinancing.downPayment,
                          downPaymentPercentage: santanderFinancing.downPaymentPct,
                          monthlyPrice: santanderFinancing.paymentPerMonth,
                          nominalInterest: santanderFinancing.nominalInterest,
                          aopBeforeTax: santanderFinancing.aopBeforeTax,
                          totalLoanCost: santanderFinancing.totalLoanCosts,
                          totalRepay: santanderFinancing.totalRepay,
                          santanderId: product?.purchaseTypes?.financing?.santanderId ?? null,
                      }
                    : null,
            departmentId: formValues.dealershipId ?? null,
            departmentName: formValues.dealershipName ?? '',
            url: formValues.url,
            ...(formValues.companyName && { companyName: formValues.companyName }),
            ...(formValues.postalCode && { postalCode: formValues.postalCode }),
        };
    };

    return {
        getOrderCarModalSubmitData,
        vehicleToSubmit,
    };
};
