import React, { VFC, useCallback } from 'react';
import { StickySummaryDesktop } from './desktop/desktop.component';
import { StickySummaryMobile } from './mobile/mobile.component';
import { hesselViewModels } from '../../../lib/view-models';
import { ProductDetailsPage } from '../../../lib/api/models/umbraco';
import { useCarCtaConfig } from '../cars/use-car-cta-config';
import { useVanCtaConfig } from '../vans/use-van-cta-config';
import { PriceByOwnership } from '../hooks/price-by-ownership.types';
import { ProductDetailsStore } from '../../../lib/state/hessel-site/product-details';
import { isNullOrEmpty } from '../../../utils/helpers';
import { OpenDialog, usePdpModals } from '../product-details-page/product-details-page.context';
import { CarLeasingOwnershipFinancialTab, CarLeasingOwnershipOperationalTab } from '../../../lib/api/models/umbraco/product-types/cars.types';

type StickySummaryProps = {
    visible: boolean;
    brandTitle: string;
    variantTitle: string;
    period: string;
    primaryCta: {
        label?: string;
        action?: () => void;
    };
    onOpenCheckout: () => void;
    selectedOwnershipTab: hesselViewModels.OwnershipTab;
    page: ProductDetailsPage;
    product: hesselViewModels.ProductDetails;
    priceInformation: PriceByOwnership;
    isCompanyCar: boolean;
};

export type StickySummarySubComponentProps = {
    visible: boolean;
    variantTitle: string;
    brandTitle: string;
    priceInfo: {
        price: number;
        downPayment?: number;
        monthly?: boolean;
        vatIncluded?: boolean;
        overrideText?: string;
        priceTextOverride?: string;
        isAFromPrice?: boolean;
    };
    period: string;
    primaryCta: {
        label?: string;
        action?: () => void;
    };
    selectedOwnershipTab: string;
    isCompanyCar: boolean;
};

const StickySummaryMemo: VFC<Omit<StickySummaryProps, 'primaryCta'>> = (props) => {
    const { page, selectedOwnershipTab, product, onOpenCheckout, isCompanyCar } = props;
    const carCtaConfig = useCarCtaConfig(page, selectedOwnershipTab, product);
    const vanCtaConfig = useVanCtaConfig(page);
    const { isAFromPrice } = ProductDetailsStore.useStoreState((state) => state);
    const { openDialog } = usePdpModals();

    const getCtaAction = useCallback(() => {
        if (isCompanyCar) {
            const companyCarCMS = props.page.carFinancialSetup.find((x) => x.alias === 'companyCar');
            const companyCarOperationalLeasingCMS = props.page.carFinancialSetup.find(
                (x) => x.alias === 'carLeasingOwnershipOperationalTab'
            ) as CarLeasingOwnershipOperationalTab;
            const companyCarFinancialLeasingCMS = props.page.carFinancialSetup.find(
                (x) => x.alias === 'carLeasingOwnershipFinancialTab'
            ) as CarLeasingOwnershipFinancialTab;

            if (companyCarCMS?.alias === 'companyCar') {
                if (selectedOwnershipTab === 'Operationel Leasing' && product.purchaseTypes.operationalLeasing.show) {
                    return {
                        label: !isNullOrEmpty(companyCarOperationalLeasingCMS?.orderCtaText)
                            ? companyCarOperationalLeasingCMS?.orderCtaText
                            : 'Få et uforpligtende tilbud',
                        action: () => openDialog({ dialog: OpenDialog.CompanyLeasing }),
                    };
                }
                if (selectedOwnershipTab === 'Finansiel Leasing' && product.purchaseTypes.financialLeasing.show) {
                    return {
                        label: !isNullOrEmpty(companyCarFinancialLeasingCMS?.orderCtaText)
                            ? companyCarFinancialLeasingCMS?.orderCtaText
                            : 'Få et uforpligtende tilbud',
                        action: () => openDialog({ dialog: OpenDialog.CompanyLeasing }),
                    };
                }

                if (
                    (selectedOwnershipTab === 'Operationel Leasing' && !product.purchaseTypes.operationalLeasing.show) ||
                    (selectedOwnershipTab === 'Finansiel Leasing' && !product.purchaseTypes.financialLeasing.show)
                ) {
                    return {
                        label: 'Kontakt os',
                        action: () => openDialog({ dialog: OpenDialog.ContactHessel }),
                    };
                }

                return {
                    label: !isNullOrEmpty(companyCarCMS.ctaText) ? companyCarCMS.ctaText : 'Book rådgiver',
                    action: () => openDialog({ dialog: OpenDialog.BookCompanyCarAdvisor }),
                };
            }

            return {
                label: 'Book rådgiver',
                action: () => openDialog({ dialog: OpenDialog.BookCompanyCarAdvisor }),
            };
        }

        switch (product.vehicleType) {
            case 'Car': {
                return {
                    label: carCtaConfig[product.availability]?.labels.primary,
                    action:
                        selectedOwnershipTab === 'Car HiRE' && product?.availability !== 'Used'
                            ? onOpenCheckout
                            : carCtaConfig[product?.availability]?.actions.primary,
                };
            }
            case 'Van': {
                return {
                    label: vanCtaConfig[product.availability]?.labels.primary,
                    action: carCtaConfig[product?.availability]?.actions.primary,
                };
            }
            default: {
                return {
                    label: 'N/A',
                    action: () => {},
                };
            }
        }
    }, [
        carCtaConfig,
        isCompanyCar,
        onOpenCheckout,
        openDialog,
        product.availability,
        product.purchaseTypes.financialLeasing.show,
        product.purchaseTypes.operationalLeasing.show,
        product.vehicleType,
        props.page.carFinancialSetup,
        selectedOwnershipTab,
        vanCtaConfig,
    ]);

    const getMobileCtaAction = () => {
        if (typeof window !== 'undefined') {
            const priceSection = document.getElementById('price-section-pdp');
            if (priceSection)
                return {
                    label: 'Se alle priser',
                    action: () =>
                        priceSection.scrollIntoView({
                            behavior: 'smooth',
                        }),
                };
        }
        return {
            label: 'N/A',
            action: () => {},
        };
    };

    if (!product) {
        return null;
    }

    return (
        <>
            <StickySummaryDesktop {...props} primaryCta={getCtaAction()} priceInfo={{ ...props.priceInformation, isAFromPrice }} />
            <StickySummaryMobile {...props} primaryCta={getMobileCtaAction()} priceInfo={{ ...props.priceInformation, isAFromPrice }} />
        </>
    );
};

export const StickySummary = React.memo(StickySummaryMemo);
