import { FC, useEffect, useMemo, useState } from 'react';
import { CardWrapper } from './vehicle-product-list-results.styled';
import { VehicleProductListMarketingCard } from '../vehicle-product-list-marketingcard';
import { ProductCard, ProductCardDetails } from '../../../../lib/view-models/vehicle';
import { generatePdpUrl, getDealershipsForTestDrives } from '../../../../utils/helpers';
import Link from 'next/link';
import { DealershipInformationPage } from '../../../../lib/api/models/umbraco';
import { VehicleProductListSendRequestCard } from '../vehicle-product-list-send-request-card/vehicle-product-list-send-request-card.component';
import { FormsSpot, PaymentType } from '../../../../lib/api/models/umbraco/content-spot';
import { useMediaQuery } from '../../../../hooks/use-media-query';
import { DepartmentFilterResponse } from '../../../../lib/api/organization/hessel-dealership-api';
import { BookTestDriveModal } from '../../../organization/book-test-drive-modal/book-test-drive-modal.component';
import { useScrollLock } from '../../../../hooks/use-scroll-lock';
import { VehicleProductListCard } from '../vehicle-product-list-card';
import { SpecialDay } from '../../../../lib/api/models/umbraco/organization.types';
import { getSiteSettings } from '../../../../lib/api';

type IProps = {
    results?: ProductCard[];
    noFilters?: boolean;
    demoCarsBookTestDriveForm?: Array<FormsSpot>;
    pageId: string;
    isAboveFold?: boolean;
    departments?: DepartmentFilterResponse[];
    paymentType?: PaymentType;
    configurationId: string;
    demoConfigurationId?: string;
    isCompanyCar?: boolean; // Temp solution for EJH-4916 until we have proper taxation
};

export const VehicleProductListResults: FC<IProps> = ({
    results,
    pageId,
    noFilters = false,
    demoCarsBookTestDriveForm = [],
    isAboveFold = false,
    departments,
    paymentType,
    configurationId,
    demoConfigurationId,
    isCompanyCar,
}) => {
    const [selectedDemoProduct, setSelectedDemoProduct] = useState<ProductCardDetails | undefined>(undefined);
    const isMobile = useMediaQuery({ target: 'mobileL' });
    const [specialDays, setSpecialDays] = useState<Array<SpecialDay>>([]);
    const departmentsAvailableForTestDrive = useMemo(() => {
        return getDealershipsForTestDrives(departments as DealershipInformationPage[]);
    }, [departments]);
    const { allResultsAreDemo } = useMemo(() => {
        return {
            allResultsAreDemo: results?.filter((x) => x.type === 'detailed').every((y) => (y as ProductCardDetails).availability === 'Demo'),
        };
    }, [results]);

    useEffect(() => {
        const getSpecialDays = async () => {
            const [response, error] = await getSiteSettings();
            if (response && !error) {
                setSpecialDays(response.marketSettings?.[0]?.dealershipSettings?.defaultSpecialDays ?? []);
            }
        };

        getSpecialDays();
    }, []);

    const priceType = useMemo(() => {
        if (paymentType === 'Leasing') return 'Leasing';
        if (paymentType === 'Taxation') return 'Taxation';
        if (paymentType === 'BusinessLeasing') return 'BusinessLeasing';
        return 'Purchase';
    }, [paymentType]);

    const { applyScrollBars, hideScrollBars } = useScrollLock();

    if (results === undefined || results?.length === 0) {
        return null;
    }

    return (
        <>
            <CardWrapper noFilters={noFilters}>
                {results.map((product, index) => {
                    const cardIsAboveFold = isMobile ? isAboveFold && index === 0 : isAboveFold && index < 6;
                    if (product.type === 'detailed') {
                        const dealershipLabel = departments?.find((x) => x.hovedafdelingId === product.locationId)?.dealershipLabel;

                        let locationBadge: string | undefined = undefined;

                        if (product.availability === 'Demo') {
                            if (dealershipLabel) {
                                locationBadge = `Kan prøves i ${dealershipLabel}`;
                            } else {
                                locationBadge = undefined;
                            }
                        }

                        if (product.availability === 'Used' || product.availability === 'Engros') {
                            locationBadge = dealershipLabel && dealershipLabel.length > 0 ? dealershipLabel : undefined;
                        }

                        const productCardUI = (
                            <VehicleProductListCard
                                productCard={product}
                                ctaSettings={
                                    allResultsAreDemo
                                        ? {
                                              onClick: (productId: string) => {
                                                  hideScrollBars();
                                                  setSelectedDemoProduct(results.find((x) => x.id === productId) as ProductCardDetails);
                                              },
                                              text: 'Book prøvetur',
                                          }
                                        : undefined
                                }
                                isAboveFold={cardIsAboveFold}
                                locationBadge={locationBadge}
                                priceType={priceType}
                                key={`plp-${product.id}`}
                                isCompanyCar={isCompanyCar}
                            />
                        );
                        if (!allResultsAreDemo) {
                            return (
                                <Link
                                    href={generatePdpUrl(product, priceType === 'Taxation' || (priceType === 'BusinessLeasing' && isCompanyCar))}
                                    key={`plp-${product.id}`}
                                >
                                    <a
                                        href={generatePdpUrl(product, priceType === 'Taxation' || (priceType === 'BusinessLeasing' && isCompanyCar))}
                                        style={{ textDecoration: 'none', width: '100%' }}
                                    >
                                        {productCardUI}
                                    </a>
                                </Link>
                            );
                        }
                        return productCardUI;
                    }

                    if (product.type === 'marketing') {
                        return (
                            <VehicleProductListMarketingCard
                                key={`marketing-${product.index}`}
                                backgroundImage={product.backgroundImage}
                                header={product.header}
                                description={product.description}
                                cta={product.cTA ? product.cTA[0] : undefined}
                                backgroundColor={product.backgroundColor}
                                textColor={product.textColor}
                                isAboveFold={cardIsAboveFold}
                            />
                        );
                    }

                    if (product.type === 'sendRequest') {
                        return (
                            <VehicleProductListSendRequestCard
                                key={`sendRequest-product-card-${index}`}
                                pageId={pageId}
                                contactForm={product.contactForm?.[0]}
                                heading={product.heading}
                                text={product.text}
                                ctaText={product.ctaText}
                                icon={product.icon}
                                isAboveFold={cardIsAboveFold}
                            />
                        );
                    }

                    return null;
                })}
            </CardWrapper>
            {allResultsAreDemo && demoCarsBookTestDriveForm?.length > 0 ? (
                <BookTestDriveModal
                    vehicleItemNumber={selectedDemoProduct?.itemNumber}
                    brand={selectedDemoProduct?.brand}
                    brandModel={selectedDemoProduct?.brandModel}
                    vehicleType={selectedDemoProduct?.category}
                    name={selectedDemoProduct?.name}
                    allDealerships={departmentsAvailableForTestDrive}
                    onClose={() => {
                        setSelectedDemoProduct(undefined);
                        applyScrollBars();
                    }}
                    visible={!!selectedDemoProduct}
                    configurationId={demoConfigurationId ? demoConfigurationId : configurationId}
                    vehicleLocationId={selectedDemoProduct?.locationId}
                    vehicleUrl={selectedDemoProduct?.url ?? ''}
                    isUsedCar={false}
                    vehicleAvailability={selectedDemoProduct?.availability}
                    testDriveAvailability="Available"
                    specialDays={specialDays}
                    referenceNumber={selectedDemoProduct?.referenceNumber}
                    vehicleEntityId={selectedDemoProduct?.entityId ?? ''}
                />
            ) : null}
        </>
    );
};
