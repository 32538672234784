import { CarColor, VehiclePurchaseType } from '../../../api/models/hessel-api/hire';
import { hesselViewModels } from '../../../view-models';
import { Equipment, EquipmentPackage } from '../../../view-models/vehicle';

export const getDownPayment = (
    purchaseTypes: VehiclePurchaseType,
    ownershipMode: hesselViewModels.OwnershipMode,
    selectedTab: hesselViewModels.OwnershipTab
): number => {
    if (ownershipMode === 'Køb') {
        if (selectedTab.includes('Finansiering')) {
            return purchaseTypes.financing.downPayment;
        } else {
            return 0;
        }
    }

    if (selectedTab.includes('Operationel')) {
        return purchaseTypes.operationalLeasing.downPayment;
    } else if (selectedTab.includes('Finansiel')) {
        return purchaseTypes.financialLeasing.downPayment;
    } else if (selectedTab.includes('Privat')) {
        return purchaseTypes.privateLeasing.downPayment;
    }

    return 0;
};

/**
 * Calculate monthly price by selected correct purchaseTypes based on owbershipMode and selectedTab
 * will add configuration prices if provided.
 */
export const getMonthlyPrice = (
    purchaseTypes: VehiclePurchaseType,
    ownershipMode: hesselViewModels.OwnershipMode,
    selectedTab: hesselViewModels.OwnershipTab,
    selectedColor?: hesselViewModels.ProductColor,
    equipmentPriceSum = 0
): number => {
    if (ownershipMode === 'Køb') {
        if (selectedTab.includes('Finansiering')) {
            return purchaseTypes.cash.basePrice + (selectedColor?.cashPrice || 0) + equipmentPriceSum;
        } else return 0;
    }

    if (selectedTab.includes('Operationel')) {
        return purchaseTypes.operationalLeasing.baseMonthlyPrice + (selectedColor?.monthlyOperationalLeasingPrice || 0) + equipmentPriceSum;
    } else if (selectedTab.includes('Finansiel')) {
        return purchaseTypes.financialLeasing.baseMonthlyPrice + (selectedColor?.monthlyFinancialLeasingPrice || 0) + equipmentPriceSum;
    } else if (selectedTab.includes('Privat')) {
        return purchaseTypes.privateLeasing.baseMonthlyPrice + (selectedColor?.monthlyPrivateLeasingPrice || 0) + equipmentPriceSum;
    } else if (selectedTab === 'Car HiRE') {
        return purchaseTypes.hire.baseMonthlyPrice + (selectedColor?.monthlyHirePrice || 0) + equipmentPriceSum;
    }

    return 0;
};

export const getCashPrice = (
    purchaseTypes: VehiclePurchaseType,
    ownershipMode: hesselViewModels.OwnershipMode,
    _selectedTab: hesselViewModels.OwnershipTab,
    selectedColor?: hesselViewModels.ProductColor,
    equipmentPriceSum = 0
): number => {
    if (ownershipMode !== 'Køb') {
        return 0;
    }

    return purchaseTypes.cash.basePrice + (selectedColor?.cashPrice || 0) + equipmentPriceSum;
};

export const calculateIsAFromPrice = (selectedTab: hesselViewModels.OwnershipTab, equipmentItems?: Array<Equipment | EquipmentPackage>): boolean => {
    if (!equipmentItems?.length) {
        return false;
    }
    switch (selectedTab) {
        case 'Car Privat Leasing':
            return equipmentItems.find((x) => x.isSelected && x.monthlyPrivateLeasingPrice === -1) !== undefined;
        case 'Finansiel Leasing':
            return equipmentItems.find((x) => x.isSelected && x.monthlyFinancialLeasingPrice === -1) !== undefined;
        case 'Operationel Leasing':
            return equipmentItems.find((x) => x.isSelected && x.monthlyOperationalLeasingPrice === -1) !== undefined;
        default:
            return false;
    }
};

export const getSummedEquipmentPrice = (
    selectedTab: hesselViewModels.OwnershipTab,
    equipmentItems?: Array<Equipment | EquipmentPackage>,
    priceTypeOverwrite?: 'cash' | 'monthly'
): number => {
    if (!equipmentItems?.length) {
        return 0;
    }

    return equipmentItems.reduce((prev, item) => {
        if (item.isSelected) {
            let priceValue: number | undefined = 0;
            if (selectedTab.includes('Kontant')) {
                priceValue = item.cashPrice;
            }
            if (selectedTab.includes('Finansiering')) {
                priceValue = item.cashPrice;
            }
            if (selectedTab.includes('Operationel Leasing')) {
                priceValue = priceTypeOverwrite === 'cash' ? item.cashPrice : item.monthlyOperationalLeasingPrice;
            } else if (selectedTab.includes('Finansiel Leasing')) {
                priceValue = priceTypeOverwrite === 'cash' ? item.cashPrice : item.monthlyFinancialLeasingPrice;
            } else if (selectedTab === 'Car HiRE') {
                priceValue = item.monthlyHirePrice;
            } else if (selectedTab === 'Car Privat Leasing') {
                priceValue = item.monthlyPrivateLeasingPrice;
            }
            if (priceValue === -1) priceValue = 0;
            return prev + (priceValue || 0);
        }
        return prev;
    }, 0);
};

/**
 * Utility function for get the total price independent of state and selected ownership tab.
 * Financing yields 0 result as the data is not available for eg. color. Use cash and figure it out from Santander.
 * @returns Price object with price, whether it is a monthly price and downPayment.
 */
export const getAllIncludedPrice = (
    purchaseType: hesselViewModels.OwnershipTab,
    product?: hesselViewModels.ProductDetails,
    color?: CarColor
): { price: number; monthly: boolean; downPayment: number } => {
    if (!product) {
        return { price: 0, monthly: false, downPayment: 0 };
    }

    switch (purchaseType) {
        case 'Finansiel Leasing':
            return {
                price:
                    (product.purchaseTypes.financialLeasing.baseMonthlyPrice || 0) +
                    (color?.monthlyFinancialLeasingPrice || 0) +
                    getPriceForAllEquipment(product, purchaseType),
                monthly: true,
                downPayment: product.purchaseTypes.financialLeasing.downPayment || 0,
            };
        case 'Operationel Leasing':
            return {
                price:
                    (product.purchaseTypes.operationalLeasing.baseMonthlyPrice || 0) +
                    (color?.monthlyOperationalLeasingPrice || 0) +
                    getPriceForAllEquipment(product, purchaseType),
                monthly: true,
                downPayment: product.purchaseTypes.operationalLeasing.downPayment || 0,
            };
        case 'Car Privat Leasing':
            return {
                price:
                    (product.purchaseTypes.privateLeasing.baseMonthlyPrice || 0) +
                    (color?.monthlyPrivateLeasingPrice || 0) +
                    getPriceForAllEquipment(product, purchaseType),
                monthly: true,
                downPayment: product.purchaseTypes.privateLeasing.downPayment || 0,
            };
        case 'Car HiRE':
            return {
                price:
                    (product.purchaseTypes.hire.baseMonthlyPrice || 0) +
                    (color?.monthlyHirePrice || 0) +
                    getPriceForAllEquipment(product, purchaseType),
                monthly: true,
                downPayment: 0,
            };
        case 'Car Kontant':
        case 'Van Kontant':
            return {
                price: (product.purchaseTypes.cash.basePrice || 0) + (color?.cashPrice || 0) + getPriceForAllEquipment(product, purchaseType),
                monthly: false,
                downPayment: 0,
            };
        default:
            return {
                price: 0,
                monthly: false,
                downPayment: 0,
            };
    }
};

export const getPriceForAllEquipment = (product: hesselViewModels.ProductDetails, selectedTab: hesselViewModels.OwnershipTab): number => {
    return (
        getSummedEquipmentPrice(selectedTab, product?.optionalEquipment[selectedTab]) +
        getSummedEquipmentPrice(selectedTab, product?.extraEquipment[selectedTab]) +
        getSummedEquipmentPrice(selectedTab, product?.extraEquipmentPackages[selectedTab])
    );
};

export const getCashPriceForAllEquipment = (product: hesselViewModels.ProductDetails, selectedTab: hesselViewModels.OwnershipTab): number => {
    return (
        getSummedEquipmentPrice(selectedTab, product?.optionalEquipment[selectedTab], 'cash') +
        getSummedEquipmentPrice(selectedTab, product?.extraEquipment[selectedTab], 'cash') +
        getSummedEquipmentPrice(selectedTab, product?.extraEquipmentPackages[selectedTab], 'cash')
    );
};
